import {useCallback, useEffect, useRef, useState} from "react";
import {iFrameHost, PropsFromCartRedux} from "./BuildOwn";
import { PropsFromScreenManager } from "../../types";
import {useMediaQuery} from "react-responsive";

const useBuildOwn = (props:PropsFromScreenManager & PropsFromCartRedux) => {
  const {instanceData: { screenManager },ring,footer,setCartImageSuccess,setCartThumbImageSuccess} = props
  const [isIcon, setIsIcon] = useState(true);
  const [viewSrc, setViewSrc] = useState("");
  const [iframeLoadActionCount, setIframeLoadActionCount] = useState<number>(0);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const currentIcon = !isIcon ? "hk-AR" : "hk-image";
  const [image,setImage] = useState<string[]>([]);
  const [thumbImage,setThumbImage] = useState<string[]>([]);
  const [handViewImage,setHandViewImage] = useState<{[key:string]:string}>({});
  const [isSticky, setSticky] = useState(false);
  const byoSliderRoot = useRef<HTMLDivElement>(null);
  const islargeTab = useMediaQuery({ query: '(max-width: 1279px)' });
  useEffect(() => {
    setViewSrc(iFrameHost);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (Object.keys(ring.options).length > 0) {
        const { Ring_Style, Ring_Crown, Center_Diamond_Size, Ring_Side, Diamond_Shape, Color, Single_First_Metal_Band, Double_First_Metal_Band, Double_Second_Metal_Band, Wedding_Band } = ring.options;
        if (footer.styleID && Ring_Crown && Diamond_Shape && Center_Diamond_Size && Ring_Side) {
          const imageSKU = `${Ring_Style}-${Ring_Crown}-${Ring_Side}`;
          // const lowQuality = await getLowQualityRender(Ring_Style, Ring_Crown, Ring_Side);
          setImage([]);
          setThumbImage([]);

          const style = footer.styleID.includes('RP') ? `${footer.styleID}-${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}` : `${footer.styleID}-${Ring_Style}${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}`;
          const uniqueName = footer.styleID.includes('RP') ? `${footer.styleID}${Ring_Crown}${Diamond_Shape}${Center_Diamond_Size}${Ring_Side}` : `${footer.styleID}${Ring_Style}${Ring_Crown}${Diamond_Shape}${Center_Diamond_Size}${Ring_Side}${Color}${Color}${(Single_First_Metal_Band || Double_First_Metal_Band) ? `${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `${Double_Second_Metal_Band?.split('_')[1]}` : ''}${Wedding_Band === "Double" ? 'C1' : Wedding_Band === "Single" ? 'C2' : 'C3'}`;
          setHandViewImage({
            image:`${process.env.REACT_APP_IMAGE_KEY}/${style}/${Color}-${Color}${(Single_First_Metal_Band || Double_First_Metal_Band) ? `-${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `-${Double_Second_Metal_Band?.split('_')[1]}` : ''}/${Wedding_Band === "Double" ? 'C1' : Wedding_Band === "Single" ? 'C2' : 'C3'}/V5/2000/fafafa/lq/${uniqueName}V52000.jpg`,
            thumbPath:`${process.env.REACT_APP_IMAGE_KEY}/${style}/${Color}-${Color}${(Single_First_Metal_Band || Double_First_Metal_Band) ? `-${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `-${Double_Second_Metal_Band?.split('_')[1]}` : ''}/${Wedding_Band === "Double" ? 'C1' : Wedding_Band === "Single" ? 'C2' : 'C3'}/V5/150/fafafa/lq/${uniqueName}V5150.jpg`
          });

          ["V1", "V2", "V3", "V4"].forEach((view: string) => {
            const imagePath = `${process.env.REACT_APP_IMAGE_KEY}/${style}/${Color}-${Color}${(Single_First_Metal_Band || Double_First_Metal_Band) ? `-${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `-${Double_Second_Metal_Band?.split('_')[1]}` : ''}/${Wedding_Band === "Double" ? 'C1' : Wedding_Band === "Single" ? 'C2' : 'C3'}/${view}/2000/fafafa/lq/${uniqueName}${view}2000.jpg`;
            const thumbPath = `${process.env.REACT_APP_IMAGE_KEY}/${style}/${Color}-${Color}${(Single_First_Metal_Band || Double_First_Metal_Band) ? `-${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `-${Double_Second_Metal_Band?.split('_')[1]}` : ''}/${Wedding_Band === "Double" ? 'C1' : Wedding_Band === "Single" ? 'C2' : 'C3'}/${view}/150/fafafa/lq/${uniqueName}${view}150.jpg`;
            setImage((prev: string[]) => {
              return [...prev, imagePath];
            });
            setThumbImage((prev: string[]) => {
              return [...prev, thumbPath];
            });
          });
        }
      }
    }

    fetchData(); // Call the async function immediately

  }, [ring.options["Ring_Style"],ring.options["Ring_Crown"],ring.options["Diamond_Shape"],ring.options["Center_Diamond_Size"],ring.options["Ring_Side"],ring.options["Metal"],ring.options["Color"],ring.options["Diamond_Type"],ring.options["Wedding_Band"],ring.options["Single_First_Metal_Band"],ring.options["Double_Second_Metal_Band"],ring.options["Double_First_Metal_Band"]]);

  useEffect(()=>{
    if(thumbImage.length > 0 && handViewImage['image']) {
      setCartImageSuccess([...image, handViewImage['image']]);
    }
  },[image])

  useEffect(()=>{
    if(thumbImage.length > 0 && handViewImage['thumbPath']){
      setCartThumbImageSuccess([...image,handViewImage['thumbPath']]);
    }
  },[thumbImage])

  const handleChangeIcon = useCallback(() => {
    setIsIcon((icon) => !icon);
  }, [setIsIcon]);

  useEffect(() => {
    (screenManager as any).props.setStateManagerData(
      "currentScreen",
      "Select Settings screen"
    );
    (screenManager as any).props.setStateManagerData(
      "currentView",
      "Select Settings Main View"
    );
  }, []);

  useEffect(() => {
    const div = byoSliderRoot.current;
    if (!div) return;
    const handleScroll = () => {
      const scrollTop = div.scrollTop;
      setSticky(scrollTop > 0);
      const maxScrollTop = 230;
      const clampedScrollTop = Math.min(scrollTop, 230); // Cap the scrollTop value at 230px
      div.style.setProperty('--scroll-offset', `${clampedScrollTop}px`);
    };
    div.addEventListener('scroll', handleScroll);
    return () => div.removeEventListener('scroll', handleScroll);
  }, []);

  return {
    img:image,
    thumbImage,
    viewSrc,
    currentIcon,
    handleChangeIcon,
    isIcon,
    iframeLoadActionCount,
    setIframeLoadActionCount,
    islargeTab,
    isSticky,
    byoSliderRoot,
    activeIndex,
    setActiveIndex
  };
};
export default useBuildOwn;
